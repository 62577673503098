import React, { FC, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  Stack,
  StackDivider,
  Tag,
  Text,
} from "@chakra-ui/react";
import { LayoutContext } from "../../components/layout";
import { motion } from "framer-motion";
import { graphql, PageProps } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { FluidObject } from "gatsby-image";
import { Container } from "../../components/container";
import { Blocks } from "../../components/blocks";
import { BlockFragment, LotTemplateQuery } from "../../../graphql-types";
import { BrochureFooter } from "../../components/brochure/footer";
import { Footer } from "../../components/footer";
import { compact } from "lodash";
import Lot from "../../assets/svg/lot.inline.svg";
import Rooms from "../../assets/svg/rooms.inline.svg";
import House from "../../assets/svg/house.inline.svg";
import { useMachine } from "@xstate/react";
import { formSubmissionMachine } from "../../machines/form-submission";
import { Controller, useForm } from "react-hook-form";
import { ArrowForwardIcon, LinkIcon } from "@chakra-ui/icons";

export type FormData = {
  name: string;
  emailAddress: string;
  phoneNumber: string;
};

export const query = graphql`
  query LotTemplate($id: String!) {
    wpLot(id: { eq: $id }) {
      id
      title

      lotCustomFields {
        id
        livingArea
        lotArea
        numberOfRooms
        type
        state
        price {
          price
          priceSuffix
        }
        estateAgent {
          ... on WpEstateAgent {
            title
          }
        }
        houseType {
          ... on WpHouseType {
            title

            blocks {
              ...Block
            }

            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2000, quality: 80) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const formatter = new Intl.NumberFormat("nl-NL", {
  style: "currency",
  currency: "EUR",
});

const LotTemplate: FC<PageProps<LotTemplateQuery>> = props => {
  const { data } = props;
  const lot = data.wpLot;

  const { mapMachine } = useContext(LayoutContext);
  const [, mapSend] = mapMachine as NonNullable<typeof mapMachine>;

  const [formState, formSend] = useMachine(formSubmissionMachine);
  const { register, handleSubmit, errors } = useForm<FormData>();
  const onSubmit = handleSubmit(data => {
    formSend("SUBMIT", {
      fields: { ...data, lot: lot?.title },
      formType: "lot",
    });
  });

  useEffect(() => {
    mapSend("HIDE_MAP");
  }, [mapSend]);

  return (
    <motion.div
      key={props.path}
      initial={{ opacity: 0, x: "100%" }}
      animate={{ opacity: 1, x: "0%" }}
      exit={{ opacity: 0, x: "-100%" }}
      transition={{ duration: 0.5 }}
    >
      <main>
        <Box
          as={BackgroundImage}
          fluid={compact([
            `linear-gradient(rgba(0, 0, 0, 0.555), rgba(0, 0, 0, 0.404588))`,
            lot?.lotCustomFields?.houseType?.featuredImage?.node?.localFile
              ?.childImageSharp?.fluid as FluidObject,
          ])}
          pt={{ base: 48, md: 64, lg: "20rem", xl: "25rem" }}
          pb={16}
        >
          <Container maxW="xl" color="white">
            <Stack align="flex-start" spacing={6}>
              <Stack spacing={2}>
                <Heading
                  color="gold"
                  textStyle="korolevCompressed"
                  fontSize={{ base: "xl", md: "2xl" }}
                >
                  Bouwnummer {lot?.lotCustomFields?.id}
                </Heading>

                <Heading
                  textStyle="korolevCompressed"
                  fontSize={{ base: "4xl", md: "5xl", lg: "6xl", xl: "7xl" }}
                >
                  {lot?.lotCustomFields?.houseType?.title}
                </Heading>
              </Stack>

              <Stack
                spacing={3}
                direction={{ base: "column", sm: "row" }}
                align={{ base: "flex-start", sm: "center" }}
              >
                {lot?.lotCustomFields?.price?.price &&
                  lot?.lotCustomFields?.type === "houseSale" &&
                  lot?.lotCustomFields?.state !== "sold" && (
                    <Box
                      fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
                      textStyle="korolevCompressed"
                    >
                      {formatter.format(
                        lot?.lotCustomFields?.price?.price || 0
                      )}{" "}
                      <Box
                        as="span"
                        fontSize={{ base: "sm", sm: "md", md: "xl", lg: "2xl" }}
                      >
                        {lot?.lotCustomFields?.price?.priceSuffix}
                      </Box>
                    </Box>
                  )}

                <Tag
                  transform={{ md: "translateY(3px)" }}
                  borderRadius="xl"
                  bgColor={
                    // available
                    (lot?.lotCustomFields?.state === "available" &&
                      "forestGreen") ||
                    // sold
                    (lot?.lotCustomFields?.state === "sold" &&
                      "internationalOrange") ||
                    // under option
                    (lot?.lotCustomFields?.state === "underOption" && "gold") ||
                    // under development
                    "facebook.600"
                  }
                  color={
                    // available
                    (lot?.lotCustomFields?.state === "available" && "white") ||
                    // sold
                    (lot?.lotCustomFields?.state === "sold" && "white") ||
                    // under option
                    (lot?.lotCustomFields?.state === "underOption" &&
                      "black") ||
                    // under development
                    "white"
                  }
                >
                  {
                    // available
                    (lot?.lotCustomFields?.state === "available" &&
                      "beschikbaar") ||
                      // sold
                      (lot?.lotCustomFields?.state === "sold" && "verkocht") ||
                      // under option
                      (lot?.lotCustomFields?.state === "underOption" &&
                        "in optie") ||
                      // under development
                      (lot?.lotCustomFields?.state === "underDevelopment" &&
                        "in ontwikkeling")
                  }
                </Tag>
              </Stack>

              <Stack
                direction="row"
                // direction={{ base: "column", md: "row" }}
                spacing={{ base: 3, md: 6 }}
                divider={<StackDivider borderColor="whiteAlpha.400" />}
              >
                {/* living area area */}
                <Stack pr={5}>
                  <Icon as={House} boxSize={5} color="gold" />

                  <Heading size="xs" fontWeight="semibold">
                    Wonen
                  </Heading>

                  <Text fontSize="sm">
                    {lot?.lotCustomFields?.livingArea} m<sup>2</sup>
                  </Text>
                </Stack>

                {/* number of rooms */}
                <Stack pr={5}>
                  <Icon as={Rooms} boxSize={5} color="gold" />

                  <Heading size="xs" fontWeight="semibold">
                    Kamers
                  </Heading>

                  <Text fontSize="sm">
                    {lot?.lotCustomFields?.numberOfRooms}
                  </Text>
                </Stack>

                {/* lot area */}
                <Stack pr={5}>
                  <Icon as={Lot} boxSize={5} color="gold" />

                  <Heading size="xs" fontWeight="semibold">
                    Perceel
                  </Heading>

                  <Text fontSize="sm">
                    {lot?.lotCustomFields?.lotArea} m<sup>2</sup>
                  </Text>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Box>

        <Blocks
          blocks={lot?.lotCustomFields?.houseType?.blocks as BlockFragment[]}
        />

        <Box py={{ base: 6, md: 8, lg: 16 }}>
          <Container maxW="xl">
            {(() => {
              /**
               * Failure
               */
              if (formState.matches("failure")) {
                return (
                  <Stack spacing={4}>
                    <Heading textStyle="korolevCompressed" size="2xl">
                      Er is iets fout gegaan
                    </Heading>
                  </Stack>
                );
              }

              /**
               * Success
               */
              if (formState.matches("success")) {
                return (
                  <Stack spacing={4}>
                    <Heading textStyle="korolevCompressed" size="2xl">
                      Bedankt! {lot?.lotCustomFields?.estateAgent?.title} neemt
                      contact met je op
                    </Heading>

                    <Text>
                      Heb je specifieke wensen en ben je benieuwd of deze woning
                      daarbij aansluit? Download dan de brochure en bekijk de
                      plattegronden van deze woning.
                    </Text>
                  </Stack>
                );
              }

              /**
               * Idle
               */
              return (
                <Box overflow="hidden">
                  <Flex wrap="wrap" mx={-6} my={-3}>
                    <Stack
                      mx={6}
                      my={3}
                      flexGrow={1}
                      flexBasis={64}
                      spacing={4}
                    >
                      <Heading textStyle="korolevCompressed" size="2xl">
                        Meer weten over dit project?
                      </Heading>

                      <Text>
                        Laat uw gegevens achter, dan neemt de makelaar contact
                        met u op.
                      </Text>
                    </Stack>

                    <Stack
                      as="form"
                      mx={6}
                      my={3}
                      flexGrow={999}
                      minWidth="50%"
                      onSubmit={onSubmit}
                      spacing={6}
                      id="lot"
                    >
                      <FormControl isInvalid={errors.name !== undefined}>
                        <Input
                          ref={register({ required: true })}
                          name="name"
                          borderRadius="none"
                          borderWidth={0}
                          borderBottomWidth={1}
                          borderColor="blackAlpha.300"
                          placeholder="Naam"
                          _placeholder={{ color: "blackAlpha.600" }}
                        />
                        <FormErrorMessage>
                          u heeft geen naam ingevuld
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl
                        isInvalid={errors.emailAddress !== undefined}
                      >
                        <Input
                          ref={register({ required: true })}
                          type="emailAddress"
                          name="emailAddress"
                          borderRadius="none"
                          borderWidth={0}
                          borderBottomWidth={1}
                          borderColor="blackAlpha.300"
                          placeholder="E-mailadres"
                          _placeholder={{ color: "blackAlpha.600" }}
                        />
                        <FormErrorMessage>
                          u heeft geen e-mailadres ingevuld
                        </FormErrorMessage>
                      </FormControl>

                      <FormControl isInvalid={errors.phoneNumber !== undefined}>
                        <Input
                          ref={register({ required: true })}
                          type="phoneNumber"
                          name="phoneNumber"
                          borderRadius="none"
                          borderWidth={0}
                          borderBottomWidth={1}
                          borderColor="blackAlpha.300"
                          placeholder="Telefoonnummer"
                          _placeholder={{ color: "blackAlpha.600" }}
                        />
                        <FormErrorMessage>
                          u heeft geen telefoonnummer ingevuld
                        </FormErrorMessage>
                      </FormControl>

                      <Button
                        type="submit"
                        color="shark"
                        bgColor="gold"
                        _hover={{ bgColor: "supernova" }}
                        _active={{ bgColor: "corn" }}
                        rightIcon={<ArrowForwardIcon />}
                        isLoading={formState.matches("loading")}
                      >
                        Neem contact op
                      </Button>
                    </Stack>
                  </Flex>
                </Box>
              );
            })()}
          </Container>
        </Box>
      </main>

      <footer>
        <BrochureFooter />
        <Footer />
      </footer>
    </motion.div>
  );
};

export default LotTemplate;
